
import { computed, ComputedRef, defineComponent, inject, Ref, ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import LoginOrderQuery from "@/graphql/loginOrder.query.gql";
import LoginRmaQuery from "@/graphql/loginRma.query.gql";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { MarketConfig } from "@/types";

import CustomInput from "@/components/CustomInput.vue";
import LoginForm from "./LoginForm.vue";
import { OrderDetail, RmaSearchResult } from "@/types";

interface GetAllRefs {
  email: Ref<string>;
  orderId: Ref<string>;
  ticketCode: Ref<string>;
  isFormFilled: ComputedRef<boolean>;
}

interface LoginBasedOnUrlParameter {
  login: Function;
  loginIsInvalid: Ref<boolean>;
  invalidLabelText: Ref<string>;
  loginLoading: Ref<boolean>;
}

function getAllRefs(formType: string): GetAllRefs {
  const email = ref((useRoute().query.email as string) || "");
  const orderId = ref((useRoute().query.orderId as string) || "");
  const ticketCode = ref((useRoute().query.ticketId as string) || "");
  const isFormFilled = computed(() =>
    formType === "rma"
      ? ticketCode.value !== "" && email.value !== ""
      : orderId.value !== "" && email.value !== ""
  );

  return {
    email,
    orderId,
    ticketCode,
    isFormFilled
  };
}

function loginBasedOnUrlParameter(
  urlParameter: string,
  formCredentials: {
    email: Ref<string>;
    orderId: Ref<string>;
    ticketCode: Ref<string>;
  },
  isFormFilled: ComputedRef<boolean>
): LoginBasedOnUrlParameter {
  const router = useRouter();
  const { t } = useI18n();
  const cookies = inject("cookies") as any;
  const loginIsInvalid = ref(false) as Ref<boolean>;
  const queryEnabled = ref(false) as Ref<boolean>;
  const invalidLabelText =
    urlParameter === "rma"
      ? (ref(t("LOGIN.ERRORS.WRONG_CREDENTIALS_RMA")) as Ref<string>)
      : (ref(t("LOGIN.ERRORS.WRONG_CREDENTIALS_ORDER")) as Ref<string>);

  const setCookies = (email: string, orderId?: string) => {
    cookies.setCookie("email", email, { expire: 0 });
    if (orderId) cookies.setCookie("orderId", orderId, { expire: 0 });
    cookies.setCookie("isAuthenticated", "true", { expire: 0 });
  };

  const doLoginUser = (
    email: string,
    orderId: string | null,
    routeTo: string
  ) => {
    orderId ? setCookies(email, orderId) : setCookies(email);

    router.push(routeTo);
  };

  const preventLogin = (errorText: string) => {
    loginIsInvalid.value = true;
    invalidLabelText.value = errorText;
  };

  const getRouteTo = (ticketId?: string): string =>
    urlParameter === "rma" ? `/ticket-detail/${ticketId}` : "/";
  const query = urlParameter === "rma" ? LoginRmaQuery : LoginOrderQuery;

  const queryVariables = computed(() =>
    urlParameter === "rma"
      ? {
          email: formCredentials.email.value.trim(),
          rmaCode: formCredentials.ticketCode.value.trim()
        }
      : {
          email: formCredentials.email.value.trim(),
          orderId: formCredentials.orderId.value.trim()
        }
  );

  const { loading: loginLoading, onResult, onError } = useQuery(
    query,
    queryVariables,
    () => ({
      enabled: queryEnabled.value
    })
  );

  const handleOrderSearchData = (
    data: OrderDetail,
    email: string,
    orderId: string,
    routeTo: string
  ) => {
    if (data.result) {
      doLoginUser(email, orderId, routeTo);
    } else {
      if (data.reason.id === 3) {
        preventLogin(t("LOGIN.ERRORS.WRONG_CREDENTIALS_ORDER"));
      } else if (data.reason.id === 9) {
        preventLogin(t("LOGIN.ERRORS.NO_PROJECT"));
      }
    }
  };

  const handleRmaSearchData = (
    data: RmaSearchResult,
    email: string,
    routeTo: string
  ) => {
    if (data.result) {
      cookies.setCookie("token", data.token, {
        expire: 0
      });
      doLoginUser(email, null, routeTo);
    } else {
      if (data.reason.id === 1) {
        preventLogin(t("LOGIN.ERRORS.WRONG_CREDENTIALS_RMA"));
      } else if (data.reason.id === 9) {
        preventLogin(t("LOGIN.ERRORS.NO_PROJECT"));
      }
    }
  };

  const login = () => {
    if (isFormFilled.value) {
      queryEnabled.value = true;

      onResult(result => {
        if (result.data) {
          urlParameter === "rma"
            ? handleRmaSearchData(
                result.data.rmaSearch,
                formCredentials.email.value.trim(),
                getRouteTo(result.data.rmaSearch.rmaCaseId)
              )
            : handleOrderSearchData(
                result.data.orderSearch,
                formCredentials.email.value.trim(),
                formCredentials.orderId.value.trim(),
                getRouteTo()
              );
        } else {
          preventLogin(t("LOGIN.ERRORS.ATTEMPTS"));
        }
        queryEnabled.value = false;
      });

      onError(error => {
        console.log("ERROR", error);
        preventLogin(t("LOGIN.ERRORS.INTERNAL_ERROR"));
        queryEnabled.value = false;
      });
    }
  };

  return {
    loginLoading,
    login,
    loginIsInvalid,
    invalidLabelText
  };
}

export default defineComponent({
  components: {
    LoginForm,
    CustomInput
  },

  setup() {
    const { t } = useI18n();
    const urlTypeParameter = useRoute().params.param as string;

    const { email, orderId, ticketCode, isFormFilled } = getAllRefs(
      urlTypeParameter
    );

    const { change } = inject("marketConfig") as MarketConfig;

    const {
      loginLoading,
      login,
      loginIsInvalid,
      invalidLabelText
    } = loginBasedOnUrlParameter(
      urlTypeParameter,
      { email, orderId, ticketCode },
      isFormFilled
    );

    return {
      loginIsInvalid,
      invalidLabelText,
      login,
      orderId,
      email,
      change,
      isFormFilled,
      loginLoading,
      t,
      urlTypeParameter,
      ticketCode
    };
  }
});
