import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CustomInput, {
        insideText: _ctx.emailValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:emailValue', $event.target.value))),
        label: _ctx.t('COMMON.EMAIL'),
        invalid: _ctx.invalid
      }, null, 8, ["insideText", "label", "invalid"]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_Button, {
      onClick: _ctx.loginFunction,
      disabled: _ctx.isButtonDisabled,
      loadingState: _ctx.loadingState,
      text: _ctx.buttonText
    }, null, 8, ["onClick", "disabled", "loadingState", "text"])
  ]))
}