
import { computed, defineComponent } from "vue";
import CustomInput from "@/components/CustomInput.vue";
import Button from "@/components/Button.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    CustomInput,
    Button
  },
  props: {
    type: {
      type: String,
      required: true
    },
    emailValue: {
      type: String,
      required: true
    },
    invalid: {
      type: Boolean
    },
    loginFunction: {
      type: Function,
      required: true
    },
    isButtonDisabled: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    loadingState: {
      type: Boolean
    }
  },
  setup(props) {
    const { t } = useI18n();
    return {
      t,
      routeToPage: computed(() =>
        props.type === "rma" ? "/ticket-detail" : "/"
      )
    };
  }
});
